'use client'

import { Hero as HeroUI } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { useSplitStore } from '@chaseweb/utils/split'
import { useEffect, useRef } from 'react'

import { ActionableLink, DownloadLink } from '@/components'
import { FeatureActionLinkEnum, type FeatureTypeIdEnum } from '@/constants'
import { useCTAStore } from '@/lib/stores'
import type { zActionableLinkType } from '@/lib/validators'

import type { ComponentProps } from '../../types'

const defaultHeroAction: zActionableLinkType = {
  actionData: {
    action: FeatureActionLinkEnum.DOWNLOAD,
  },
  link: undefined,
}

export const HeroCta = ({
  featureData,
}: ComponentProps<FeatureTypeIdEnum.HERO_FEATURE>) => {
  const client = useSplitStore((state) => state.clients.user)
  const treatmentCtaText = client?.getTreatmentWithConfig(
    'homepage_hero_cta_text',
  )
  const treatmentCtaSize = client?.getTreatment('homepage_hero_cta_size')
  const treatmentCtaColour = client?.getTreatment('hero_cta_colour')

  const ctaRef = useRef<HTMLDivElement>(null)
  const { setHeroCtaRef, setAlwaysVisible } = useCTAStore()

  useEffect(() => {
    if (client) {
      if (ctaRef.current) {
        setHeroCtaRef(ctaRef)
        setAlwaysVisible(false)
      } else {
        setAlwaysVisible(true)
      }
    }
    return () => setHeroCtaRef(null)
  }, [setHeroCtaRef, client, setAlwaysVisible])

  const ctaLabel = featureData.isHomepage
    ? (
        JSON.parse(
          treatmentCtaText?.config ?? '{"inlineText":"Get the app"}',
        ) as {
          inlineText: string
        }
      )['inlineText']
    : featureData.cta?.label

  const isCtaBigSize = treatmentCtaSize === 'on' && featureData.isHomepage
  const isCtaWhite = treatmentCtaColour === 'on' && featureData.isHomepage

  return featureData.cta ? (
    <div ref={ctaRef} className="tw-min-h-[75px]">
      {client && (
        <HeroUI.Cta
          variant={isCtaWhite ? 'secondary' : featureData.variant}
          asChild
          className={cn({
            'tw-px-8 tw-py-4': isCtaBigSize,
          })}
        >
          <ActionableLink
            sectionLabel="hero"
            linkData={featureData.cta.linkData ?? defaultHeroAction}
          >
            {ctaLabel}
          </ActionableLink>
        </HeroUI.Cta>
      )}
      <noscript>
        <HeroUI.Cta variant={featureData.variant} asChild>
          <DownloadLink sectionLabel="hero">{ctaLabel}</DownloadLink>
        </HeroUI.Cta>
      </noscript>
    </div>
  ) : null
}
