'use client'

import { useCarouselContext } from '@chaseweb/ui-library/src/molecules/carousel/carousel.context'
import type { HTMLAttributes } from 'react'
import { useEffect, useState } from 'react'

export const animationTimer = 5000

export const CarouselPromoInterval = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const { setCurrentPage, childrenCount, currentPage } = useCarouselContext()
  const [isPlaying, setIsPlaying] = useState(false)

  const handleInOutEvent = (isInside: boolean) => () => {
    setIsPlaying(isInside)
  }

  useEffect(() => {
    if (!isPlaying && childrenCount > 1) {
      const intervalId = setInterval(() => {
        setCurrentPage((cur) => {
          return (cur + 1) % childrenCount
        })
      }, animationTimer)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [childrenCount, currentPage, isPlaying, setCurrentPage])

  return (
    <div
      onMouseEnter={handleInOutEvent(true)}
      onMouseLeave={handleInOutEvent(false)}
      onTouchStart={handleInOutEvent(true)}
      onTouchEnd={handleInOutEvent(false)}
      {...props}
    >
      {children}
    </div>
  )
}
