'use client'

import { Header, Navigation } from '@chaseweb/ui-library/src/molecules'
import { cn } from '@chaseweb/utils/cn'
import { usePathname } from 'next/navigation'

import {
  DesktopHeaderCta,
  Link,
  MobileHeaderCta,
  SkipToMainContentButton,
} from '@/components'
import { FeatureActionLinkEnum, type FeatureTypeIdEnum } from '@/constants'
import type { FeatureDataFilteredByType } from '@/data/aem/types'

type Props = {
  featureData: FeatureDataFilteredByType<FeatureTypeIdEnum.NAVIBAR_FEATURE>
  logoImage: JSX.Element
  iconImageMap: Record<string, JSX.Element | undefined>
}

const GroupOrLink = ({
  item,
  pathname,
  iconImageMap,
}: {
  item: Props['featureData']['items'][number]
  pathname: string
  iconImageMap: Props['iconImageMap']
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  if (item.type === 'group') {
    return (
      <Navigation.MenuItem>
        <Navigation.Trigger>{item.label}</Navigation.Trigger>
        <Navigation.Content>
          {item.items[0].type === 'group' ? (
            item.items.map(
              (subItem) =>
                subItem.type === 'group' && (
                  <Navigation.SubList key={subItem.id}>
                    {subItem.label && (
                      <Navigation.SubListHeader>
                        {subItem.label}
                      </Navigation.SubListHeader>
                    )}
                    {subItem.items.map((subGroupItem) => (
                      <GroupOrLink
                        item={subGroupItem}
                        key={subGroupItem.id}
                        pathname={pathname}
                        iconImageMap={iconImageMap}
                      />
                    ))}
                  </Navigation.SubList>
                ),
            )
          ) : (
            <Navigation.SubList key={item.id}>
              {item.items.map((subItem) => (
                <GroupOrLink
                  item={subItem}
                  key={subItem.id}
                  pathname={pathname}
                  iconImageMap={iconImageMap}
                />
              ))}
            </Navigation.SubList>
          )}
        </Navigation.Content>
      </Navigation.MenuItem>
    )
  } else if (item.type === 'link' && item.linkData) {
    if (item.linkData.link) {
      const isActive =
        item.linkData.link === '/'
          ? pathname === '/'
          : item.linkData.link &&
            pathname.startsWith(item.linkData.link.replace(/\/$/, ''))

      return (
        <Navigation.MenuItem
          className={cn({
            'lg:tw-hidden':
              item.visibleOnly === 'mobile' || item.linkData.link === '/',
            'max-lg:tw-hidden': item.visibleOnly === 'desktop',
          })}
        >
          <Navigation.Link
            active={isActive}
            aria-current={isActive ? 'page' : false}
            asChild
          >
            <Link
              sectionLabel="NavigationItem"
              trackingActionLabel={item.label}
              href={item.linkData.link}
            >
              {item.iconUrl && (
                <Navigation.LinkIconWrapper>
                  {iconImageMap[item.iconUrl.src]}
                </Navigation.LinkIconWrapper>
              )}
              <Navigation.LinkTextWrapper>
                {item.label}
              </Navigation.LinkTextWrapper>
            </Link>
          </Navigation.Link>
        </Navigation.MenuItem>
      )
    } else if (
      item.linkData?.actionData.action === FeatureActionLinkEnum.DOWNLOAD &&
      !/^\/?((sitemap)|(support)|(legal)|(download))\//.test(pathname)
    ) {
      return item.visibleOnly === 'desktop' ? (
        <Navigation.MenuItem className={cn('max-lg:tw-hidden')}>
          <DesktopHeaderCta />
        </Navigation.MenuItem>
      ) : (
        <Navigation.MenuItem className={cn('lg:tw-hidden')}>
          <MobileHeaderCta />
        </Navigation.MenuItem>
      )
    }
  }
}

export const ClientNavibarComponent = ({
  featureData,
  logoImage,
  iconImageMap,
}: Props) => {
  const pathname = usePathname()
  return (
    <Header.Root>
      {featureData.items.length > 0 && <SkipToMainContentButton />}
      <Header.Container className="tw-container-maxwidth">
        <Header.LogoWrapper asChild>
          {featureData.logoClickable ? (
            <Link
              notTracked
              href="/"
              className="tw-h-full"
              aria-label={featureData.logoLabel}
            >
              {logoImage}
            </Link>
          ) : (
            <div>
              <div className="tw-relative tw-h-full">{logoImage}</div>
            </div>
          )}
        </Header.LogoWrapper>
        <Header.Content>
          {featureData.items.length > 0 && (
            <Navigation.Root
              aria-label="Main navigation"
              className="header"
              contentClassName="main-navlist"
            >
              {featureData.items.map((item) => (
                <GroupOrLink
                  item={item}
                  key={item.id}
                  pathname={pathname}
                  iconImageMap={iconImageMap}
                />
              ))}
            </Navigation.Root>
          )}
        </Header.Content>
      </Header.Container>
    </Header.Root>
  )
}
